import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Welcome to my Website",
  "details": "A surprise to be sure, but a welcome one.",
  "date": "2021-05-05",
  "tags": ["Welcome"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Welcome!`}</h1>
    <p>{`On this site I share some of the many miscallaneous projects I work on and things I'm interested in.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "553px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "45.74468085106383%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAABJ0AAASdAHeZh94AAABx0lEQVQozx3NWW7TQAAAUFe1x7N6No/tGS/xks2OGzVpWAIlitqopf1BpaoQIEG/uP8REFzgPQ9DgiERgA7K1sT0RDchX2C5ifND2e1MeWznN+N6kea9ylbKjSJtgci88G3bexGkgnAMSSezGU97oobEOWFqojcmv5nMXk+n17vTULWNTN+k1dYUNWD5GdqUnccxw5DAEGdMD8KOWB2nszEvs4B9LNovy9XL1e5+dbF2k1bbYz27dk0VsMqnF6b0KCQoxCCAkvCWxGsoN1lpldGAHYrmqR+/juvb1drxlEG+rdqH/rLySYvkksVerhKBIhgi6sOJygflpjpTTBGfTKL4cT6sbaFNSVgCAggBNkxZH3dIV0h5kokIEOzDRGTDsNt1i7mxnUoSIgWWVmiMacRjigWlAmMBACYBmgQiDSPvv4dwiJK0snGxsZOFKR2M6TkF5xSGUiYNppaymOuCiozzLIoSCyLtIw+FOAxgopNYGHAWuljcva8fP5T7rbu6dNf75t1+dvhUpzaNRE54KoUTsmBIigB5EKB/MySxtiFgy2n859fq+aH++dL9/rF6flp8/7a4/zyXromko8xELOPcYSRYgP8Cl4A2EkS9fcUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A-surprise-to-be-sure.png",
          "title": "A-surprise-to-be-sure.png",
          "src": "/static/a0fa8d3d88ad2116eb015470912926a8/74cfa/A-surprise-to-be-sure.png",
          "srcSet": ["/static/a0fa8d3d88ad2116eb015470912926a8/4dcb9/A-surprise-to-be-sure.png 188w", "/static/a0fa8d3d88ad2116eb015470912926a8/5ff7e/A-surprise-to-be-sure.png 375w", "/static/a0fa8d3d88ad2116eb015470912926a8/74cfa/A-surprise-to-be-sure.png 553w"],
          "sizes": "(max-width: 553px) 100vw, 553px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Thank you for your interest in my content!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      